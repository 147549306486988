var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "40%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "20vh",
            title: "Cấp thêm SMS",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "createSchoolSmsRequest",
              attrs: {
                "label-width": "140px",
                model: _vm.createSchoolSmsRequest,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Cấp thêm SMS", prop: "smsAdd" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "Nhập số SMS muốn thêm",
                                type: "number",
                              },
                              model: {
                                value: _vm.createSchoolSmsRequest.smsAdd,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createSchoolSmsRequest,
                                    "smsAdd",
                                    $$v
                                  )
                                },
                                expression: "createSchoolSmsRequest.smsAdd",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Nội dung", prop: "content" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.createSchoolSmsRequest.content,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.createSchoolSmsRequest,
                                    "content",
                                    $$v
                                  )
                                },
                                expression: "createSchoolSmsRequest.content",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-infor-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialogByButton(
                            "createSchoolSmsRequest"
                          )
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _c("span", [_vm._v("Đóng")]),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "medium",
                        loading: _vm.loadingButton,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("createSchoolSmsRequest")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v("Lưu")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v("\n    " + _vm._s(_vm.idSchoolList) + "\n  "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }